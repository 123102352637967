//import global API
import Api from '../../api/Api'

const home = {

  namespaced: true,

  state: {
    campaignProgram1Id: '',
    campaignProgram1Title: '',
    campaignProgram1: [],
    campaignProgram2Id: '',
    campaignProgram2Title: '',
    campaignProgram2: [],
    category: [],
    slider: [],
    ebook: [],
    settingKategori: '',
    bannerImage: '',
    bannerImageSlug: '',
    realTitle: '',
    realImage: '',
    realDesc: '',
    isPopupShow: true,
    popupImage: '',
    popupLink: '',
    linkYoutube: ''
  },

  mutations: {
    SET_CAMPAIGN1_ID(state, id) { state.campaignProgram1Id = id },
    SET_CAMPAIGN1_TITLE(state, campaign1Title) { state.campaignProgram1Title = campaign1Title },
    SET_CAMPAIGN1(state, campaign1) { state.campaignProgram1 = campaign1 },
    SET_CAMPAIGN2_ID(state, id) { state.campaignProgram2Id = id },
    SET_CAMPAIGN2_TITLE(state, campaign2Title) { state.campaignProgram2Title = campaign2Title },
    SET_CAMPAIGN2(state, campaign2) { state.campaignProgram2 = campaign2 },
    SET_CATEGORY(state, category) { state.category = category },
    SET_SLIDER(state, slider) { state.slider = slider },
    SET_EBOOK(state, ebook) { state.ebook = ebook },
    SET_SETTING_KATEGORI(state, settingKategori) { state.settingKategori = settingKategori },
    SET_BANNER_IMAGE(state, banner) { state.bannerImage = banner },
    SET_BANNER_IMAGE_SLUG(state, slug) { state.bannerImageSlug = slug },
    SET_REAL_TITLE(state, title) { state.realTitle = title },
    SET_REAL_IMAGE(state, image) { state.realImage = image },
    SET_REAL_DESC(state, desc) { state.realDesc = desc },
    SET_POPUP_SHOW(state, popup) { state.isPopupShow = popup },
    SET_POPUP_IMAGE(state, image) { state.popupImage = image },
    SET_POPUP_LINK(state, link) { state.popupLink = link },
    SET_YOUTUBE(state, youtube) { state.linkYoutube = youtube }
  },

  actions: {
    getHomeData({ commit }) {
      Api.get('/home')
        .then(response => {
          let result = response.data
          console.log('hasilnya ==> ', result)

          commit('SET_CAMPAIGN1_ID', result.dataCampaignProgram1.id)
          commit('SET_CAMPAIGN1_TITLE', result.dataCampaignProgram1.judul)
          commit('SET_CAMPAIGN1', result.dataCampaignProgram1.data)
          commit('SET_CAMPAIGN2_ID', result.dataCampaignProgram2.id)
          commit('SET_CAMPAIGN2_TITLE', result.dataCampaignProgram2.judul)
          commit('SET_CAMPAIGN2', result.dataCampaignProgram2.data)
          commit('SET_CATEGORY', result.dataCategory)
          commit('SET_SLIDER', result.dataSlider)
          commit('SET_EBOOK', result.dataEbook)
          commit('SET_SETTING_KATEGORI', result.dataSettingKategori)
          commit('SET_BANNER_IMAGE', result.image)
          commit('SET_BANNER_IMAGE_SLUG', result.campaign_slug)
          commit('SET_REAL_TITLE', result.dataRealisasi.judul)
          commit('SET_REAL_IMAGE', result.dataRealisasi.image)
          commit('SET_REAL_DESC', result.dataRealisasi.deskripsi)
          commit('SET_POPUP_IMAGE', result.popup.image)
          commit('SET_POPUP_LINK', result.popup.link)
          commit('SET_YOUTUBE', result.linkYoutube)

        }).catch(e => {
          console.log(e)
        })
    },

    setPopupShow({ commit }, value) {
      commit('SET_POPUP_SHOW', value);
    }
  },

  getters: {

  }
}

export default home
